import React from 'react';
import { Gallery } from 'gatsby-theme-gallery';
import { Layout } from '../components/common';
import { Header } from '../components/theme';
import SEO from '../components/seo';
export default class Gall extends React.Component {

  render() {
    return (
      <Layout>
        <SEO title="Galeria" />
        <Header />
        <Gallery/>
      </Layout>
    )
  }
}